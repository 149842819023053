import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Welcome } from 'components/atomic/atoms';
import StandardLayout from 'components/layout/StandardLayout';
import { getPlannerName } from 'services/identity.service';
import { getPageURL, PAGE_NAME } from 'services/plannerPortal.service';

const Index = () => {
  const router = useRouter();

  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const name = getPlannerName();
  const shouldRedirectToActionCenterDashboard = name;

  useEffect(() => {
    if (shouldRedirectToActionCenterDashboard) {
      router.push(getPageURL({ pageName: PAGE_NAME.ACTION_CENTER.label }));
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <StandardLayout
      {...{
        headParameters: {
          metaDescription:
            'Your one-stop shop for event planning. Discover powerful tools and features designed to streamline your workflow.',
          metaTitle: 'Hafla Planner Portal | Your Event Planning Hub'
        },
        isMenuOpen,
        loading,
        mainStyle: 'mt-0',
        pageDataFound: true,
        setIsMenuOpen,
        showHeader: true
      }}
    >
      <Welcome />
    </StandardLayout>
  );
};

export default Index;
